import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchTermsAndConditions(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/terms-and-conditions', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateTermsAndConditions(ctx, {data}) {
            console.log(data)
            return new Promise((resolve, reject) => {
                axios
                    .put('/terms-and-conditions/', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
